import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

import { Link } from "gatsby";
import { Logo } from "components/Logo";

const pages = ["contact", "event-services", "about-us"];

export function Header({ overlay = false }) {
  const [navOpen, setNavOpen] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setNavOpen(false);
  }, [pathname]);

  return (
    <nav
      id="header"
      className={`${
        overlay ? "absolute" : "relative"
      } flex site-header w-full z-30 top-0`}
      style={
        overlay && !navOpen
          ? {
              background:
                "linear-gradient(0deg,rgba(0,0,0,0),rgb(0 0 0 / 45%))",
              "--on-site-header": "#fff",
            }
          : null
      }
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-6">
        <div className="flex items-center">
          <Link activeClassName="active" to="/">
            <Logo className="w-32 sm:w-44 h-auto" />
          </Link>
        </div>
        <div className="block lg:hidden">
          <button
            id="nav-toggle"
            onClick={() => setNavOpen(!navOpen)}
            className="flex items-center p-1 text-white hover:text-white focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          style={
            navOpen
              ? {
                  display: "flex",
                  top: "var(--site-header-height)",
                  background: "var(--site-header)",
                  left: 0,
                }
              : {}
          }
          className="w-full absolute lg:relative flex-col lg:flex-row lg:flex lg:items-center lg:w-auto hidden lg:mt-0 p-4 lg:p-0 z-20"
          id="nav-content"
        >
          <ul className="menu list-reset lg:flex justify-end flex-1 items-center">
            <li className="relative">
              <a
                href="/events/all"
                className="inline-block py-2 px-4 font-bold no-underline"
              >
                EVENTS
              </a>
              <ul className="ml-8 mb-4 lg:ml-4 list-reset flex-1 lg:absolute">
                <li>
                  <a href="/events/fresh-festival">FRESH FESTIVAL</a>
                </li>
                <li>
                  <a href="/events/letsdisko">LETSDISKO</a>
                </li>
                <li>
                  <a href="/events/athena-sit-down-social">
                    ATHENA SIT DOWN SOCIAL
                  </a>
                </li>
                <li>
                  <a href="/events/student-race-day">STUDENT RACE DAY</a>
                </li>
              </ul>
            </li>
            {pages.map((p, i) => (
              <li key={i} className="mr-3">
                <Link
                  className="inline-block py-2 px-4 font-bold no-underline"
                  to={`/${p}`}
                  activeClassName="active"
                >
                  {p.toUpperCase().replace(/-/g, " ")}
                </Link>
              </li>
            ))}
            <li className="mr-3">
              <a
                className="inline-block py-2 px-4 font-bold no-underline"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSefqpNymiF0xDMdCKVnCbtPBCTBbT2Kf3inJtWXgNxxOsbgIA/viewform?usp=sf_link"
              >
                JOIN THE TEAM
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
